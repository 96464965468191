import ENV from "../config/config";

const URL_PREFIX = ENV === "main" ? "" : ENV + ".";

const Url =
  ENV === "main"
    ? "https://backend-rc-dashboard.metawing.ai/vahan/"
    : "https://" + URL_PREFIX + "component.dolphinchat.ai/vahan/";

const BASE_URL = {
  icici_logs: {
    url: Url,
    version: "v1/",
  },
};

export default BASE_URL;
